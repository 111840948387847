<template>
  <v-container fluid>
    <div>
      <iframe
        :src="misiteURL"
        width="100%"
        height="800"
        title="MiSiteWeb"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MiSiteWeb',
  data: function () {
    return {
      misiteURL: process.env.VUE_APP_MISITE_URL
    }
  },
  computed: {
    ...mapGetters(['loggedIn', 'cognitoInfo'])
  }
}
</script>
